<template>
  <div>
    <el-dialog v-model="dialogVisible" width="900px" append-to-body :show-close="false">
      <template #title>
        <div class="dialog_box">
          <div class="con_title"><span></span> 选择产品批号</div>
        </div>
      </template>
      <!-- <el-table :data="list" v-loading="loading" v-if="!is_sale" style="width: 100%" ref="tableDataRefs"
        :class="is_single ? 'is_single' : ''" @selection-change="handleSelectionChange"> -->
      <el-table :data="list" v-loading="loading" v-if="!is_sale" style="width: 100%" ref="tableDataRefs"
         @selection-change="handleSelectionChange">
        <el-table-column prop="warehouse_no" label="货位编号" />
        <el-table-column prop="warehouse_name" label="货位名称" />
        <el-table-column prop="batch_number" label="批号" />
        <el-table-column label="有效期至">
          <template v-slot="scope">
            {{ scope.row.valid_until || '长效期' }}
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="库存数量" />
        <el-table-column prop="price" label="成本单价" />
        <el-table-column type="selection" width="55" />
      </el-table>

      <pagination v-show="total > 0" :total="total" v-model:page="queryParams.page" v-model:limit="queryParams.size"
        @pagination="getList" />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :disabled="multiple" @click="confirm_sele">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'selectProducts',
  props: {

  },
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
      },
      total: 0,
      list: [],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      dialogVisible: false,
      disabled_ids: [],  // 上级页面已选择的，这里不能再选
      is_single: false,   //是否只能选一个产品
    }
  },

  methods: {
    init(id, index, once) {
      this.queryParams = {
        product_id: id,
        size: 10
      }
      this.do_index = index;
      this.is_single = 1;
      this.getList(once)
    },
    confirm_sele() {
      this.$emit('after_sele', { row: this.selection, index: this.do_index });
      this.dialogVisible = false;
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      // if (selection.length <= 1) {
        this.ids = selection.map(item => item.id);
      // }
      // if (selection.length > 1) {
      //   this.ids = [selection[1].id]
      //   let del_row = selection.shift()
      //   this.$refs.tableDataRefs.toggleRowSelection(del_row, false)
      // }
      this.selection = selection;
      this.single = this.ids.length != 1;
      this.multiple = !this.ids.length;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.tableDataRefs.toggleRowSelection(row);
        });
      } else {
        this.$refs.tableDataRefs.clearSelection();
      }
    },
    /** 查询列表 */
    getList(once) {
      this.loading = true;
      let url = '/backend/ProductStock/productBatchNumber';
      this.$httpGet(url, this.queryParams).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.list = res.data.data;
          if (once) {
            if (this.list.length == 1) {
              this.ids = [this.list[0].id];
              // this.$refs.tableDataRefs.toggleRowSelection(this.list[0], true);
              this.selection = this.list
              this.confirm_sele()
            } else {
              this.dialogVisible = true;
            }
          } else {
            this.dialogVisible = true;
          }

          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    }
  }
}
</script>

<style scoped>
.dialog_box {
  display: flex;
  justify-content: space-between;
}
</style>
